const registrationRoutes = {
	path: '/registrations',
	name: 'module.registrations',
	redirect: { name: 'registrations.registrations' },
	meta: {
		requiresAuth: true,
		permissions: [ 'registrations' ]
	},
	component: () => import('@/modules/registrations/RegistrationsModule.vue'),
	children: [
		{
			path: 'registrations',
			name: 'registrations.registrations',
			redirect: { name: 'registrations.registrations.index' },
			children: [
				{
					path: '',
					name: 'registrations.registrations.index',
					component: () => import('@/modules/registrations/pages/RegistrationsIndex.vue'),
					meta: { permissions: [ 'registrations' ] }
				},
				{
					path: ':registrationId(\\d+)',
					name: 'registrations.registrations.show',
					component: () => import('@/modules/registrations/pages/RegistrationPage.vue'),
					props: { isNew: false, isEdit: false },
					meta: { permissions: [ 'registrations' ] },
				},
				{
					path: ':registrationId(\\d+)/edit',
					name: 'registrations.registrations.edit',
					component: () => import('@/modules/registrations/pages/RegistrationPage.vue'),
					props: { isNew: false, isEdit: true },
					meta: { permissions: [ 'registrations' ] },
				}
			]
		},
		{
			path: 'deviations',
			name: 'registrations.deviations',
			redirect: { name: 'registrations.deviations.index' },
			children: [
				{
					path: '',
					name: 'registrations.deviations.index',
					component: () => import('@/modules/registrations/pages/DeviationsIndex.vue')
				},
				{
					path: ':deviationId(\\d+)',
					name: 'registrations.deviations.show',
					component: () => import('@/modules/registrations/pages/DeviationPage.vue'),
					props: { isEdit: false }
				},
				{
					path: ':deviationId(\\d+)/edit',
					name: 'registrations.deviations.edit',
					component: () => import('@/modules/registrations/pages/DeviationPage.vue'),
					props: { isEdit: true }
				}
			]
		},
		{
			path: 'actions',
			name: 'registrations.actions',
			redirect: { name: 'registrations.actions.index' },
			children: [
				{
					path: '',
					name: 'registrations.actions.index',
					component: () => import('@/modules/registrations/pages/ActionsIndex.vue')
				},
				{
					path: ':actionId(\\d+)',
					name: 'registrations.actions.show',
					component: () => import('@/modules/registrations/pages/ActionPage.vue'),
					props: { isEdit: false }
				},
				{
					path: ':actionId(\\d+)/edit',
					name: 'registrations.actions.edit',
					component: () => import('@/modules/registrations/pages/ActionPage.vue'),
					props: { isEdit: true }
				}
			]
		},
		{
			path: 'planning',
			name: 'registrations.planning',
			redirect: {
				name: 'registrations.planning.index'
			},
			children: [
				{
					path: '',
					name: 'registrations.planning.index',
					component: () => import('@/modules/registrations/pages/PlanningIndex.vue')
				},
				{
					path: 'add',
					name: 'registrations.planning.create',
					component: () => import('@/modules/registrations/pages/PlannedQuestionnaire.vue'),
					meta: { permissions: [ 'registrations.plans.plans.create' ] },
					props: { isNew: true, isEdit: true, },
				},
				{
					path: ':plannedQuestionnaireId(\\d+)',
					name: 'registrations.planning.show',
					component: () => import('@/modules/registrations/pages/PlannedQuestionnaire.vue'),
					props: { isNew: false, isEdit: false, }
				},
				{
					path: ':plannedQuestionnaireId(\\d+)/edit',
					name: 'registrations.planning.edit',
					component: () => import('@/modules/registrations/pages/PlannedQuestionnaire.vue'),
					props: { isNew: false, isEdit: true, }
				}
			]
		},
		{
			name: 'registrations.statistics.index',
			path: 'statistics',
			component: () => import('@/modules/registrations/pages/StatisticsIndex.vue'),
		},
		{
			path: 'questionnaires',
			name: 'registrations.manage',
			redirect: { name: 'registrations.manage.index' },
			children: [
				{
					path: '',
					name: 'registrations.manage.index',
					component: () => import('@/modules/registrations/pages/QuestionnairesIndex.vue'),
					meta: { permissions: [ 'registrations.questionnaire.questionnaire' ] }
				},
				{
					path: 'create',
					name: 'registrations.questionnaires.create',
					component: () => import('@/modules/registrations/pages/QuestionnairePage.vue'),
					props: { isNew: true, isEdit: true, }
				},
				{
					path: ':questionnaireId(\\d+)',
					name: 'registrations.questionnaires.show',
					component: () => import('@/modules/registrations/pages/QuestionnairePage.vue'),
					props: { isNew: false, isEdit: false, }
				},
				{
					path: ':questionnaireId(\\d+)/edit',
					name: 'registrations.questionnaires.edit',
					component: () => import('@/modules/registrations/pages/QuestionnairePage.vue'),
					props: { isNew: false, isEdit: true, }
				},
				{
					path: 'versions/:questionnaireVersionId(\\d+)',
					name: 'registrations.questionnaires.versions.show',
					component: () => import('@/modules/registrations/pages/QuestionnaireVersion.vue'),
					props: { isNew: false, isEdit: false, }
				}
			]
		}
	]
}
export default registrationRoutes
