import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { usePermissionsStore } from '@/stores/usePermissionsStore.js'

/**
 * @param {string} token
 * @returns {object} the headers
 */
export function getHeaders(token) {
	const user = useAuthorizationStore().user()
	return {
		Accept: 'application/json',
		Authorization: token ? `Bearer ${token}` : 'Bearer',
		'X-localization': user.system_language ?? localStorage.language,
		'X-fqdn': (window.location.hostname === 'localhost' ? 'local.host.fqdn' : window.location.hostname),
		'X-Requested-With': 'XMLHttpRequest'
	}
}

/**
 * @param {Array} supervisorIds
 * @param {number} relationId
 * @returns {boolean} whether the user is a supervisor for the given ids
 */
export function isSupervisor(supervisorIds, relationId) {
	const user = useAuthorizationStore().user()
	const hasSupervisorPermission = usePermissionsStore().has('relations.supervisor.supervisor')
	// Check the passed id's coming from the supplier_group departments
	const isDirectSupervisor = supervisorIds.includes(user.id)
	// Check if the user belongs to the relation
	const employeeOfRelation = relationId === user.relation_id
	// ID should be the ID of the supplier group supervisor
	return hasSupervisorPermission || isDirectSupervisor || employeeOfRelation
}
