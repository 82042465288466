import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import devConsole from '@/helpers/logging/devConsole.js'
import handleApiObject from '@/helpers/logging/handleApiObject.js'
import { computed, ref, watchEffect } from 'vue'
import { defaultExpiresTime, errorRetryTime, nowTime } from '@/stores/storeDefaults.js'
import { defineStore } from 'pinia'
import { languages } from '@/helpers/i18n.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { useColorMode, useLocalStorage, useStorage } from '@vueuse/core'

const defaults = {
	buttons: 'icon_text',
	colorMode: 'light',
	gravatar: '0',
	dateFormat: 'DD-MM-YYYY',
	dateSeparator: '-',
	timeFormat: '24',
}

export const usePreferencesStore = defineStore('preferences', () => {
	const currentLocale = useLocalStorage('language', 'en')
	const language = computed(() => languages.find(language => language.code === currentLocale.value))
	defaults.dateFormat = language.value.dateFormat
	defaults.dateSeparator = language.value.dateSeparator

	const preferences = useStorage('preferences', defaults, sessionStorage, { mergeDefaults: true })
	const loading = ref(false)
	const saving = ref(false)
	const expires = useStorage('preferences-expires', null, sessionStorage)

	watchEffect(() => {
		const { store: themeStore } = useColorMode({ attribute: 'theme' })
		themeStore.value = preferences.value.colorMode
	})

	const get = preference => {
		fetchPreferences()
		if (preference === 'dateFormat') {
			return language.value.dateFormat
		}
		if (preference === 'dateSeparator') {
			return language.value.dateSeparator
		}
		return preferences.value?.[preference] ?? false
	}

	const list = computed(() => {
		fetchPreferences()
		return preferences.value
	})

	/**
	 * Fetch preferences
	 * @param {boolean} [force]
	 * @returns {Promise} A promise to act upon
	 */
	async function fetchPreferences(force = false) {
		if (force) { loading.value = false }
		if (loading.value) { return }
		if (new Date().getTime() < expires.value && !force) { return }
		if (!useAuthorizationStore().accessToken()) { return }

		loading.value = true
		await axiosHttpClient
			.get('user/preferences')
			.then(response => {
				const data = response?.data ?? {}
				preferences.value = { ...defaults, ...data }
				expires.value = nowTime() + defaultExpiresTime
			})
			.catch(e => {
				devConsole('debug', 'usePreferencesStore: fetch failed')
				expires.value = nowTime() + errorRetryTime
				handleApiObject(e)
			})
			.finally(() => { loading.value = false })
	}

	async function save() {
		saving.value = true
		await axiosHttpClient
			.put('user/preferences', preferences.value)
			.catch(handleApiObject)
			.finally(() => { saving.value = false })
	}

	/**
	 * Clean up the data
	 */
	async function remove() {
		devConsole('debug', 'usePreferencesStore:remove')
		loading.value = false
		preferences.value = defaults
		expires.value = 0
	}

	return {
		preferences,
		loading,
		saving,
		expires,
		get,
		list,
		fetch: fetchPreferences,
		save,
		remove
	}
})
