const adminRoutes =
	{
		name: 'admin',
		path: '/admin',
		meta: { requiresAuth: true, },
		component: () => import('@/modules/admin/AdminModule.vue'),
		children: [
			{
				name: 'admin.suppliers',
				path: 'suppliers',
				component: () => import('@/modules/admin/pages/AdminSuppliers.vue')
			},
			{
				name: 'admin.registrations',
				path: 'registrations',
				component: () => import('@/modules/admin/pages/AdminRegistrations.vue')
			},
			{
				name: 'admin.manuals',
				path: 'manuals',
				component: () => import('@/modules/admin/pages/AdminManuals.vue')
			},
			{
				name: 'admin.sensory',
				path: 'sensory',
				component: () => import('@/modules/admin/pages/AdminSensory.vue')
			},
			{
				name: 'admin.elearning',
				path: 'elearning',
				component: () => import('@/modules/admin/pages/AdminElearning.vue')
			},
			{
				name: 'admin.modules',
				path: 'modules',
				component: () => import('@/modules/admin/pages/AdminModules.vue')
			},
			{
				name: 'admin.features',
				path: 'features',
				component: () => import('@/modules/admin/pages/AdminFeatures.vue')
			}
		]
	}

export default adminRoutes
