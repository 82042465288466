/**
 * @param {string[]} messages
 * @returns {HTMLUListElement} an html element with all messages as list items
 */
export default function errorList(messages) {
	if (!(messages instanceof Array)) {
		throw new TypeError('messages is not an array')
	}

	let list = document.createElement('ul')

	messages.forEach(message => {
		if (typeof message !== 'string') {
			throw new TypeError(`message '${message}' is not a string`)
		}

		let listItem = document.createElement('li')
		listItem.textContent = message
		list.appendChild(listItem)
	})

	return list
}
