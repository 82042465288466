import { i18n } from '@/helpers/i18n.js'
import swal from '@/helpers/sweetalert/sweetalert.js'

/**
 * Show an alert with an 'error' icon to confirm something went wrong
 * Disappears automatically
 * @param {string} [text] The text to display
 * @returns {Promise} A Promise that resolves/rejects after the alert is shown
 */
export default async function showError(text = i18n.global.t('alerts.error')) {
	return swal({
		className: 'shadow-dreamy',
		icon: 'error',
		text,
		buttons: false
	})
}
