const settingsRoutes = {
	name: 'settings',
	path: '/settings',
	redirect: { name: 'settings.languages' },
	meta: { requiresAuth: true, },
	component: () => import('@/modules/settings/SettingsModule.vue'),
	children: [
		{
			name: 'settings.api',
			path: 'api',
			redirect: { name: 'settings.api.index' },
			children: [
				{
					name: 'settings.api.index',
					path: '',
					component: () => import('@/modules/settings/pages/api/APIConnectionsIndex.vue')
				},
				{
					name: 'settings.api.show',
					path: ':APIId(\\d+)',
					component: () => import('@/modules/settings/pages/api/APIConnectionPage.vue'),
					props: { isEdit: false }
				},
				{
					name: 'settings.api.edit',
					path: ':APIId(\\d+)/edit',
					component: () => import('@/modules/settings/pages/api/APIConnectionPage.vue'),
					props: { isEdit: true }
				},
				{
					name: 'settings.api.log',
					path: 'logs/:APIId(\\d+)',
					component: () => import('@/modules/settings/pages/api/APILogPage.vue')
				}
			]
		},
		{
			name: 'settings.languages',
			path: 'languages',
			redirect: { name: 'settings.languages.index' },
			children: [
				{
					name: 'settings.languages.index',
					path: '',
					component: () => import('@/modules/settings/pages/LanguagesIndex.vue')
				},
				{
					name: 'settings.languages.create',
					path: 'create',
					component: () => import('@/modules/settings/pages/LanguagePage.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					name: 'settings.languages.show',
					path: ':languageId(\\d+)',
					component: () => import('@/modules/settings/pages/LanguagePage.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					name: 'settings.languages.edit',
					path: ':languageId(\\d+)/edit',
					component: () => import('@/modules/settings/pages/LanguagePage.vue'),
					props: { isNew: false, isEdit: true }
				}
			]
		},
		{
			name: 'settings.roles',
			path: 'roles',
			redirect: { name: 'settings.roles.index' },
			children: [
				{
					name: 'settings.roles.index',
					path: '',
					component: () => import('@/modules/settings/pages/RolesIndex.vue')
				},
				{
					name: 'settings.roles.create',
					path: 'create',
					component: () => import('@/modules/settings/pages/RolePage.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					name: 'settings.roles.show',
					path: ':roleId(\\d+)',
					component: () => import('@/modules/settings/pages/RolePage.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					name: 'settings.roles.edit',
					path: ':roleId(\\d+)/edit',
					component: () => import('@/modules/settings/pages/RolePage.vue'),
					props: { isNew: false, isEdit: true }
				}
			]
		},
		{
			name: 'settings.templates',
			path: 'templates',
			redirect: { name: 'settings.templates.index' },
			children: [
				{
					name: 'settings.templates.index',
					path: '',
					component: () => import('@/modules/settings/pages/TemplatesIndex.vue')
				},
				{
					name: 'settings.templates.create',
					path: 'create',
					component: () => import('@/modules/settings/pages/TemplatePage.vue'),
					props: { isNew: true, isEdit: true, }
				},
				{
					name: 'settings.templates.show',
					path: ':templateId(\\d+)',
					component: () => import('@/modules/settings/pages/TemplatePage.vue'),
					props: { isNew: false, isEdit: false, }
				},
				{
					name: 'settings.templates.edit',
					path: ':templateId(\\d+)/edit',
					component: () => import('@/modules/settings/pages/TemplatePage.vue'),
					props: { isNew: false, isEdit: true, }
				}
			]
		},
		{
			name: 'settings.notifications',
			path: 'notifications',
			component: () => import('@/modules/settings/pages/NotificationsIndex.vue'),
			children: [
				{
					name: 'settings.notifications.suppliers',
					path: 'suppliers',
					component: () => import('@/modules/settings/pages/suppliers/SuppliersNotifications.vue')
				},
				{
					name: 'settings.notifications.registrations',
					path: 'registrations',
					component: () => import('@/modules/settings/pages/registrations/RegistrationsNotifications.vue')
				},
				{
					name: 'settings.notifications.sensory',
					path: 'sensory',
					redirect: { name: 'settings.notifications.sensory.index' },
					children: [
						{
							name: 'settings.notifications.sensory.index',
							path: '',
							component: () => import('@/modules/settings/pages/sensory/Sensory.vue'),
						},
						{
							name: 'settings.notifications.sensory.create',
							path: 'create',
							component: () => import('@/modules/settings/pages/NotificationForm.vue'),
							props: { isNew: true, isEdit: true, },
						},
						{
							name: 'settings.notifications.sensory.edit',
							path: ':notificationId(\\d+)/edit',
							component: () => import('@/modules/settings/pages/NotificationForm.vue'),
							props: { isNew: false, isEdit: true, },
						}
					]
				},
				{
					name: 'settings.notifications.elearning',
					path: 'elearning',
					component: () => import('@/modules/settings/pages/elearning/ELearningNotifications.vue')
				},
				{
					name: 'settings.notifications.manuals',
					path: 'manuals',
					redirect: { name: 'settings.notifications.manuals.index' },
					children: [
						{
							name: 'settings.notifications.manuals.index',
							path: '',
							component: () => import('@/modules/settings/pages/manuals/Manuals.vue'),
						},
						{
							name: 'settings.notifications.manuals.create',
							path: 'create',
							component: () => import('@/modules/settings/pages/NotificationForm.vue'),
							props: { isNew: true, isEdit: true, },
						},
						{
							name: 'settings.notifications.manuals.edit',
							path: ':notificationId(\\d+)/edit',
							component: () => import('@/modules/settings/pages/NotificationForm.vue'),
							props: { isNew: false, isEdit: true, },
						}
					]
				},
				{
					name: 'settings.notifications.audits',
					path: 'audits',
					component: () => import('@/modules/settings/pages/audits/AuditsNotifications.vue')
				},
			]
		},
		{
			name: 'settings.suppliers',
			path: 'supplier',
			redirect: { name: 'settings.suppliers.instructions' },
			component: () => import('@/modules/settings/pages/suppliers/SupplierSettings.vue'),
			children: [
				{
					name: 'settings.suppliers.instructions',
					path: 'instructions',
					component: () => import('@/modules/settings/pages/suppliers/SuppliersGeneralSettings.vue')
				},
				{
					name: 'settings.suppliers.supplierStatus',
					path: 'supplier-status',
					component: () => import('@/modules/settings/pages/suppliers/SupplierStatuses.vue')
				},
				{
					name: 'settings.suppliers.additionalFileTypes',
					path: 'additional-file-types',
					component: () => import('@/modules/settings/pages/suppliers/AdditionalFiles.vue')
				},
				{
					name: 'settings.suppliers.certificateInstitutions',
					path: 'certificate-institutions',
					component: () => import('@/modules/settings/pages/suppliers/CertificateInstitutions.vue')
				},
				{
					name: 'settings.suppliers.certificateRemarks',
					path: 'certificate-remarks',
					component: () => import('@/modules/settings/pages/suppliers/CertificateRemarks.vue')
				},
				{
					name: 'settings.suppliers.certificateTypes',
					path: 'certificate-types',
					component: () => import('@/modules/settings/pages/suppliers/CertificateTypes.vue')
				},
				{
					name: 'settings.suppliers.customFields',
					path: 'custom-fields',
					component: () => import('@/modules/settings/pages/suppliers/CustomFields.vue')
				},
				{
					name: 'settings.suppliers.labels',
					path: 'labels',
					component: () => import('@/modules/settings/pages/suppliers/LabelsPage.vue')
				},
				{
					name: 'settings.suppliers.productCertificates',
					path: 'product-certificates',
					component: () => import('@/modules/settings/pages/suppliers/ProductCertificates.vue')
				},
				{
					name: 'settings.suppliers.productGroups',
					path: 'product-groups',
					component: () => import('@/modules/settings/pages/suppliers/ProductGroups.vue')
				},
				{
					name: 'settings.suppliers.productSpecificationRequirements',
					path: 'product-specification-requirements',
					component: () => import('@/modules/settings/pages/suppliers/ProductSpecificationRequirements.vue')
				},
				{
					name: 'settings.suppliers.productTypes',
					path: 'product-types',
					component: () => import('@/modules/settings/pages/suppliers/ProductTypes.vue')
				},
				{
					name: 'settings.suppliers.sections',
					path: 'sections',
					component: () => import('@/modules/settings/pages/suppliers/QuestionnaireSections.vue')
				},
				{
					name: 'settings.suppliers.supplierGroups',
					path: 'supplier-groups',
					component: () => import('@/modules/settings/pages/suppliers/SupplierGroups.vue')
				},
				{
					name: 'settings.suppliers.supplierTypes',
					path: 'supplier-types',
					component: () => import('@/modules/settings/pages/suppliers/SupplierTypes.vue')
				}
			]
		},
		{
			name: 'settings.registrations',
			path: 'registrations',
			redirect: { name: 'settings.registrations.custom-fields' },
			component: () => import('@/modules/settings/pages/registrations/RegistrationSettings.vue'),
			children: [
				{
					name: 'settings.registrations.custom-fields',
					path: 'custom-fields',
					component: () => import('@/modules/settings/pages/registrations/CustomFields.vue')
				},
				{
					name: 'settings.registrations.categories',
					path: 'categories',
					component: () => import('@/modules/settings/pages/registrations/CategorySettings.vue')
				},
				{
					name: 'settings.registrations.groups',
					path: 'groups',
					component: () => import('@/modules/settings/pages/registrations/QuestionGroups.vue')
				},
				{
					name: 'settings.registrations.sub-groups',
					path: 'subgroups',
					component: () => import('@/modules/settings/pages/registrations/QuestionSubGroups.vue')
				}
			]
		},
		{
			name: 'settings.elearning',
			path: 'e-learning',
			redirect: { name: 'settings.elearning.general' },
			component: () => import('@/modules/settings/pages/elearning/ELearningSettings.vue'),
			children: [
				{
					name: 'settings.elearning.general',
					path: 'general',
					component: () => import('@/modules/elearning/components/Settings/ELearningGeneralSettings.vue')
				},
				{
					name: 'settings.elearning.full-competences',
					path: 'full-competences',
					component: () => import('@/modules/elearning/components/Settings/FullCompetences.vue')
				},
				{
					name: 'settings.elearning.function-profiles',
					path: 'function-profiles',
					component: () => import('@/modules/elearning/components/Settings/FunctionProfiles.vue')
				},
				{
					name: 'settings.elearning.partial-competences',
					path: 'partial-competences',
					component: () => import('@/modules/elearning/components/Settings/PartialCompetences.vue')
				}
			]
		},
		{
			name: 'settings.manuals',
			path: 'manuals',
			component: () => import('@/modules/settings/pages/manuals/ManualsSettings.vue'),
			meta: { permissions: [ 'manuals.settings' ] },
			children: [
				{
					name: 'settings.manuals.labels',
					path: 'labels',
					component: () => import('@/modules/settings/pages/manuals/ExtraLabels.vue'),
					meta: { permissions: [ 'manuals.settings.labels' ] },
				}
			]
		},
		{
			name: 'settings.lab',
			path: 'lab',
			component: () => import('@/modules/settings/pages/lab/LabSettings.vue')
		},
		{
			name: 'settings.audits',
			path: 'audits',
			component: () => import('@/modules/settings/pages/audits/AuditsSettings.vue')
		},
		{
			name: 'settings.sensory',
			path: 'sensory',
			component: () => import('@/modules/settings/pages/SensorySettings.vue'),
			meta: { permissions: [ 'sensory.settings' ] },
			children: [
				{
					name: 'settings.sensory.conclusions',
					path: 'conclusions',
					component: () => import('@/modules/settings/pages/sensory/ConclusionIndex.vue')
				}
			]
		}
	]
}
export default settingsRoutes
