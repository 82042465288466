import CLASS_NAMES from '../class-list';

const {
  MODAL,
} = CLASS_NAMES;

export const modalMarkup: string =`
  <div class="${MODAL}" role="dialog" aria-modal="true">` +

    // Icon

    // Title

    // Text

    // Content

    // Buttons

 `</div>`
;

export default modalMarkup;

