import { getHeaders } from '@/helpers/oauth.js'
import { i18n } from '@/helpers/i18n.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import axios from 'axios'
import devConsole from '@/helpers/logging/devConsole.js'
import FileSaver from 'file-saver'
import httpStatus from '@/helpers/httpStatus.js'
import showError from '@/helpers/alertMessages/showError.js'

const axiosHttpClient = axios.create({
	baseURL: `${import.meta.env.VITE_API_URL}/api/v1`
})

/**
 * @param {object} cfg
 * @param {string} [cfg.method]
 * @param {string} [cfg.url]
 * @param {object} [cfg.data]
 * @param {object} [cfg.config]
 * @param {string|null} [cfg.fileName]
 * @returns {Promise} A promise with a file
 */
axiosHttpClient.getFile = ({
	method = 'GET',
	url = 'does-not-exist',
	data = {},
	config = { responseType: 'arraybuffer' },
	fileName = null
} = {}) => {
	return new Promise((resolve, reject) =>
		axiosHttpClient
			.request({ method, url, data, ...config })
			.then(response => {
				if (response?.status === httpStatus.OK) {
					const possibleFileNames = [
						fileName,
						response?.headers?.['x-content-filename'],
						response?.headers?.['content-disposition']?.split('filename=')?.[1]?.split(';')?.[0].replaceAll('"', ''),
						'filename.extension'
					]
					const dynamicFileName = possibleFileNames.filter(Boolean)[0]
					FileSaver.saveAs(new File([ response.data ], dynamicFileName))
					resolve()
				} else {
					reject('File status is not OK')
				}
			})
			.catch(e => reject(e)))
}

axiosHttpClient.postFile = (url, file) => {
	return new Promise((resolve, reject) => {
		const fd = new FormData()
		fd.append('file', file)
		axiosHttpClient
			.post(url, fd)
			.then(response => {
				if (response?.status === httpStatus.OK) {
					resolve()
				} else {
					reject('File status is not OK')
				}
			})
			.catch(e => reject(e))
	})
}

axiosHttpClient.interceptors.request.use(config => {
	const headers = getHeaders(useAuthorizationStore().accessToken())
	config.headers = { ...headers, ...config.headers }
	return config
})

axiosHttpClient.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response
}, async function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	if (error?.response?.status === httpStatus.UNAUTHORIZED) {
		if (!error.request.responseURL.endsWith('auth/login') && !error.request.responseURL.endsWith('auth/logout')) {
			// eslint-disable-next-line no-console
			console.info('response was unauthorized')
			devConsole('debug', 'axiosHttpClient intercepted an unauthorized response')
			useAuthorizationStore().logout()
			await showError(i18n.global.t('auth.sessionExpired'))
			return Promise.resolve()
		}
	}
	return Promise.reject(error)
})

export default axiosHttpClient
