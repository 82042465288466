<template>
	<div id="app">
		<metainfo>
			<template #title="{ content, metainfo }">
				{{
					metainfo.titleTemplate
						? `${metainfo.titleTemplate.replace('%s', content)} - ${APP_NAME}`
						: `${content} - ${APP_NAME}`
				}}
			</template>
		</metainfo>
		<component
			:is="layoutComponent"
			:key="layoutComponent"
		>
			<router-view />
		</component>

		<Debugger />
		<widget-container-modal />
	</div>
</template>

<script>
import { container } from 'jenesius-vue-modal'
import { defineAsyncComponent } from 'vue'
import { setI18nLanguage } from '@/helpers/i18n.js'
import capitalize from '@/helpers/strings/capitalize.js'
import getCurrentLanguage from '@/helpers/getCurrentLanguage.js'
import { useMeta } from 'vue-meta'

const Debugger = defineAsyncComponent(() => import('@/components/Debug/DebuggerTool.vue'))
const LayoutAuth = defineAsyncComponent(() => import('@/components/Layouts/LayoutAuth.vue'))
const LayoutNone = defineAsyncComponent(() => import('@/components/Layouts/LayoutNone.vue'))
const LayoutNormal = defineAsyncComponent(() => import('@/components/Layouts/LayoutNormal.vue'))

export default {
	name: 'NFO',

	components: {
		LayoutAuth,
		Debugger,
		LayoutNone,
		LayoutNormal,
		WidgetContainerModal: container
	},

	setup() {
		useMeta({
			title: 'Home',
			titleTemplate: '%s'
		})

		const APP_NAME = import.meta.env.VITE_APP_NAME
		return { APP_NAME }
	},

	data() {
		return {
			layout: 'none'
		}
	},

	computed: {
		layoutComponent() {
			return 'Layout' + capitalize(this.layout)
		}
	},

	watch: {
		'$i18n.locale'(language) {
			this.$i18n.locale = language
			this.$dayjs.locale(language)
			localStorage.language = language
			document.querySelector('html').setAttribute('lang', language)
		},

		$route() {
			this.layout = this.$route?.meta?.layout ?? 'normal'
			window._paq.push([ 'setCustomDimension', 1, window.innerWidth ])
			window._paq.push([ 'setCustomDimension', 2, window.innerHeight ])
		}
	},

	mounted() {
		const language = getCurrentLanguage()
		setI18nLanguage(language)
	},

	created() {
		this.layout = this.$route?.meta?.layout ?? 'normal'
	}
}
</script>

<style lang="scss" src="./styles/App.scss"></style>
