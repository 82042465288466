const innovativeFreshRoutes = {
	path: '/innovative-fresh',
	name: 'innovativeFresh',
	redirect: { name: 'innovativeFresh.examinations' },
	meta: {
		requiresAuth: true,
		permissions: []
	},
	component: () => import('@/modules/innovative-fresh/InnovativeFreshModule.vue'),
	children: [
		{
			name: 'innovativeFresh.examinations',
			path: 'investigations',
			meta: { permissions: [], },
			template: '<router-view></router-view>',
			redirect: { name: 'innovativeFresh.examinations.index' },
			children: [
				{
					name: 'innovativeFresh.examinations.index',
					path: '',
					meta: { permissions: [], },
					component: () => import('@/modules/innovative-fresh/pages/ExaminationIndex.vue')
				},
				{
					name: 'innovativeFresh.examinations.create',
					path: 'create',
					meta: { permissions: [], },
					props: { isNew: true, isEdit: true },
					component: () => import('@/modules/innovative-fresh/pages/ExaminationPage.vue')
				},
				{
					name: 'innovativeFresh.examinations.show',
					path: ':examinationId(\\d+)',
					meta: { permissions: [], },
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/innovative-fresh/pages/ExaminationPage.vue')
				},
				{
					name: 'innovativeFresh.examinations.edit',
					path: ':examinationId(\\d+)/edit',
					meta: { permissions: [], },
					props: { isNew: false, isEdit: true },
					component: () => import('@/modules/innovative-fresh/pages/ExaminationPage.vue')
				}
			]
		},
	]
}
export default innovativeFreshRoutes
