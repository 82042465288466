import doNothing from '@/helpers/api/doNothing.js'
import devConsole from '@/helpers/logging/devConsole.js'
import errorBag from '@/helpers/logging/errorBag.js'
import { i18n } from '@/helpers/i18n.js'
import { CanceledError } from 'axios'
import swal from '@/helpers/sweetalert/sweetalert.js'

/**
 * @param {CanceledError | object} error
 * @param {object=} error.response
 * @param {string} error.message
 * @param {object=} error.response.data
 * @returns {object} an empty object
 */
export default function handleApiObject(error) {
	if (error instanceof CanceledError) { return {} }

	devConsole('error', 'return with object', error)

	swal({
		title: i18n.global.t('alerts.error'),
		icon: 'error',
		content: error?.response?.data ? errorBag(error.response.data) : error?.message,
		buttons: false
	}).catch(doNothing)

	return {}
}
