/**
 * Uppercase the first letter of a string
 * @param {string} string the string to capitalize
 * @param {boolean} everyWord
 * @returns {string} The capitalized string
 */
export default function capitalize(string, everyWord = false) {
	if (everyWord) {
		return string.split(' ').map(segment => {
			return segment.charAt(0).toLocaleUpperCase() + segment.slice(1)
		}).join(' ')
	}
	return string.charAt(0).toLocaleUpperCase() + string.slice(1)
}
