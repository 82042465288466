/**
 * Log a message only if debug mode is on with log levels
 * 'level' is one 'error', 'warn', 'log', 'info', 'debug'
 * - 'error' means stuff is broken
 * - 'warn' is to communicate to for example not use something
 * - 'log' is generic and just logs stuff
 * - 'info' is informative about app state for example
 * - 'debug' is the deeper information to pinpoint problems
 * @param {string} level The level of the logging, one of 'error', 'warn', 'log', 'info', 'debug'
 * @param {...*} args anything to log
 * @returns {void} Logs the message
 */
export default function devConsole(level = 'log', ...args) {
	if (localStorage?.debug === 'true') {
		// eslint-disable-next-line no-console
		console[level](...args)
	}
}
