import devConsole from '@/helpers/logging/devConsole.js'
import errorList from '@/helpers/logging/errorList.js'

/**
 * @param {object} [data]
 * @param {string} [data.error]
 * @param {string} [data.error.message]
 * @param {Array} [data.errors]
 * @param {string} [data.message]
 * @returns {HTMLUListElement} an HTML list
 */
export default function errorBag(data) {
	devConsole('debug', data)
	let errorMessages = []

	if (typeof data === 'string') {
		errorMessages.push(data)
	}

	if (typeof data?.error === 'string' && data?.error) {
		errorMessages.push(data.error)
	}

	if (data?.error?.message) {
		errorMessages.push(data.error.message)
	}

	if (data?.message) {
		errorMessages.push(data.message)
	}

	if (data?.errors) {
		Object.values(data.errors).forEach(error => {
			if (typeof error === 'string') {
				errorMessages.push(error)
			} else {
				error.map(subError => errorMessages.push(subError))
			}
		})
	}

	return errorList(errorMessages)
}
