export const datetimeFormats = {
	debug: {
		news: { year: 'numeric', month: 'long', day: 'numeric' },
		short: {
			year: 'numeric', month: '2-digit', day: '2-digit'
		},
		long: {
			year: 'numeric', month: '2-digit', day: '2-digit',
			hour: 'numeric', minute: 'numeric'
		},
		time: {
			hour: 'numeric', minute: 'numeric'
		}
	},
	de: {
		news: { year: 'numeric', month: 'long', day: 'numeric' },
		short: {
			year: 'numeric', month: '2-digit', day: '2-digit'
		},
		long: {
			year: 'numeric', month: '2-digit', day: '2-digit',
			hour: 'numeric', minute: 'numeric'
		},
		time: {
			hour: 'numeric', minute: 'numeric'
		}
	},
	nl: {
		news: { year: 'numeric', month: 'long', day: 'numeric' },
		short: {
			year: 'numeric', month: '2-digit', day: '2-digit'
		},
		long: {
			year: 'numeric', month: '2-digit', day: '2-digit',
			hour: 'numeric', minute: 'numeric'
		},
		time: {
			hour: 'numeric', minute: 'numeric'
		}
	},
	fr: {
		news: { year: 'numeric', month: 'long', day: 'numeric' },
		short: {
			year: 'numeric', month: '2-digit', day: '2-digit'
		},
		long: {
			year: 'numeric', month: '2-digit', day: '2-digit',
			hour: 'numeric', minute: 'numeric'
		},
		time: {
			hour: 'numeric', minute: 'numeric'
		}
	},
	en: {
		news: { year: 'numeric', month: 'long', day: 'numeric' },
		short: {
			day: 'numeric', month: 'numeric', year: 'numeric'
		},
		long: {
			day: 'numeric', month: 'numeric', year: 'numeric',
			hour: 'numeric', minute: 'numeric'
		},
		time: {
			hour: 'numeric', minute: 'numeric', hour12: true
		}
	}
}
