/**
 * Get the current timestamp
 * @returns {number} the current time as a number
 */
export function nowTime() { return new Date().getTime() }

/**
 * How long to wait before retrying a failed store request
 * @type {number}
 */
export const errorRetryTime = 1000 // 1 second

/**
 * How long to wait before retrying a news request
 * @type {number}
 */
export const newsRetryTime = 5 * 60 * 1000 // 5 minutes

/**
 * How long before store data expires and should be fetched again
 * @type {number}
 */
export const defaultExpiresTime = 2 * 60 * 60 * 1000 // 2 hours
