import getTippyEl from '@/helpers/dom/getTippyEl.js'
import tippy from 'tippy.js'

const tippyRefPlugin = {
	name: 'ref',
	defaultValue: null,
	fn() {
		return {}
	}
}

tippy.setDefaultProps({ plugins: [ tippyRefPlugin ], theme: 'light-border' })

export const tooltipDirective = {
	beforeMount(el, binding) {
		// el.tabIndex = 0
		const possiblePositions = [
			'top',
			'top-start',
			'top-end',
			'right',
			'right-start',
			'right-end',
			'bottom',
			'bottom-start',
			'bottom-end',
			'left',
			'left-start',
			'left-end',
			'auto',
			'auto-start',
			'auto-end'
		]
		const modifierNames = Object.keys(binding.modifiers)
		const positioningMods = modifierNames?.find(item => possiblePositions.includes(item)) ?? null
		const isHtml = modifierNames.includes('html') || modifierNames.includes('interactive')
		const isInteractive = modifierNames.includes('interactive')

		if (binding?.value?.trigger !== 'manual') {
			el.tabIndex = 0
		}

		const defaultOptions = {
			allowHTML: isHtml,
			appendTo: getTippyEl(),
			interactive: isInteractive,
			interactiveBorder: 20,
			maxWidth: 600,
			placement: positioningMods ?? 'top'
		}

		if (typeof binding.value === 'object') {
			// or more than text
			tippy(el, { ...defaultOptions, ...binding.value })
		} else {
			// only text
			tippy(el, { ...defaultOptions, content: binding.value })
		}
	},

	mounted(el, binding) {
		if (typeof binding.value === 'object') {
			if (binding?.value?.content) {
				el._tippy.enable()
				el._tippy.setContent(binding.value.content)
			}
		} else {
			if (binding.value) {
				el._tippy.enable()
				el._tippy.setContent(binding.value)
			} else {
				el._tippy.disable()
			}
		}

		if (binding?.value?.ref) {
			// document.getElementById(binding.value.ref).style.display = 'none'
			const element = document.getElementById(binding.value.ref)
			el._tippy.setProps({ allowHTML: true })
			el._tippy.setContent(element)
		}
	},

	updated(el, binding) {
		if (binding?.value?.trigger !== 'manual') {
			el.tabIndex = 0
		}

		if (typeof binding.value === 'object') {
			if (binding?.value?.trigger) {
				el._tippy.setProps({ trigger: binding.value.trigger })
			}

			if (binding?.value?.content) {
				el._tippy.enable()
				el._tippy.setContent(binding.value.content)
			}
		} else {
			if (binding.value) {
				el._tippy.enable()
				el._tippy.setContent(binding.value)
			} else {
				el._tippy.disable()
			}
		}

		if (binding?.value?.ref) {
			// document.getElementById(binding.value.ref).style.display = 'none'
			const element = document.getElementById(binding.value.ref)
			if (element) {
				el._tippy.setProps({ allowHTML: true })
				el._tippy.setContent(element)
			}
		}
	},

	beforeUnmount(el) {
		el?._tippy?.destroy()
	}
}
