import devConsole from '@/helpers/logging/devConsole.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
const languages = [ 'nl', 'en', 'fr' ]

/**
 * @returns {string|boolean} the language in the URL
 */
function languageFromQueryString() {
	const urlParams = new URLSearchParams(window.location.search)
	let queryLanguage = false
	if (urlParams.has('lang') && languages.includes(urlParams.get('lang'))) {
		queryLanguage = urlParams.get('lang')
	}
	return queryLanguage
}

/**
 * Check the current language from the browser, query, stored or system language with a fallback to 'en'
 * @returns {string} the language string
 */
function getCurrentLanguage() {
	let language = 'en'
	devConsole('debug', 'language', language)

	const browserLanguage = window.navigator.language
	if (browserLanguage !== null) {
		const localeParts = browserLanguage.split('-')
		devConsole('debug','localeParts', localeParts)

		const browserLanguagePart = localeParts[0].toLowerCase()
		if (languages.includes(browserLanguagePart)) {
			devConsole('debug','using browser language', browserLanguagePart)
			language = browserLanguagePart
		}
	}

	const queryLanguage = languageFromQueryString()
	if (queryLanguage && languages.includes(queryLanguage)) {
		devConsole('debug','using language from query string', queryLanguage)
		language = queryLanguage
	}

	const storedLanguage = localStorage.language
	if (storedLanguage !== null && languages.includes(storedLanguage)) {
		devConsole('debug','using stored language', storedLanguage)
		language = storedLanguage
	}

	const user = useAuthorizationStore().user()
	const systemLanguage = user?.system_language?.replace('_', '-').split('-')[0].toLowerCase()
	if (systemLanguage !== undefined) {
		devConsole('debug','using user system language', systemLanguage)
		language = systemLanguage
	}

	return language
}

export default getCurrentLanguage
