const labRoutes = {
	path: '/lab',
	name: 'lab',
	redirect: { name: 'lab.products' },
	component: () => import('@/modules/lab/LabModule.vue'),
	meta: {
		requiresAuth: true,
		permissions: [ 'lab' ],
	},
	children: [
		{
			name: 'lab.products',
			path: 'products',
			redirect: { name: 'lab.products.index' },
			children: [
				{
					name: 'lab.products.index',
					path: '',
					component: () => import('@/modules/lab/pages/ProductsIndex.vue'),
					meta: { permissions: [ 'lab.products.products' ] },
				},
				{
					name: 'lab.products.create',
					path: 'create',
					props: { isNew: true, isEdit: true },
					component: () => import('@/modules/lab/pages/ProductDetail.vue'),
					meta: { permissions: [ 'lab.products.products.create' ] },
				},
				{
					name: 'lab.products.edit',
					path: ':productId(\\d+)/edit',
					props: { isNew: false, isEdit: true },
					component: () => import('@/modules/lab/pages/ProductDetail.vue'),
					meta: { permissions: [ 'lab.products.products.update' ] },
				},
				{
					name: 'lab.products.show',
					path: ':productId(\\d+)',
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/lab/pages/ProductDetail.vue'),
					meta: { permissions: [ 'lab.products.products.show' ] },
				},
			],
		},
		{
			name: 'lab.orders',
			path: 'orders',
			redirect: { name: 'lab.orders.index' },
			children: [
				{
					name: 'lab.orders.index',
					path: '',
					component: () => import('@/modules/lab/pages/OrdersIndex.vue'),
					meta: { permissions: [ 'lab.orders.orders' ] },
				},
				{
					name: 'lab.orders.show',
					path: ':orderId(\\d+)',
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/lab/pages/OrderDetail.vue'),
					meta: { permissions: [ 'lab.orders.orders.show' ] },
				},
			],
		},
		{
			name: 'lab.samples',
			path: 'samples',
			redirect: { name: 'lab.samples.index' },
			children: [
				{
					name: 'lab.samples.index',
					path: '',
					component: () => import('@/modules/lab/pages/SamplesIndex.vue'),
					meta: { permissions: [ 'lab.samples.samples' ] },
				},
				{
					name: 'lab.samples.show',
					path: ':sampleId(\\d+)',
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/lab/pages/Sample.vue'),
					meta: { permissions: [ 'lab.samples.samples.show' ] },
				},
			],
		},
		{
			name: 'lab.results',
			path: 'results',
			redirect: { name: 'lab.results.index' },
			children: [
				{
					name: 'lab.results.index',
					path: '',
					component: () => import('@/modules/lab/pages/ResultsIndex.vue'),
					meta: { permissions: [ 'lab.results.results' ] },
				},
			],
		},
		{
			name: 'lab.statistics',
			path: 'statistics',
			redirect: { name: 'lab.statistics.index' },
			children: [
				{
					name: 'lab.statistics.index',
					path: '',
					component: () => import('@/modules/lab/pages/StatisticsIndex.vue'),
					meta: { permissions: [ 'lab.statistics.statistics' ] },
				},
			],
		},
	],
}
export default labRoutes
