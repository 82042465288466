const suppliersRoutes = {
	path: '/suppliers-index',
	name: 'module.suppliers-index',
	redirect: { name: 'suppliers-index.suppliers.index' },
	meta: {
		requiresAuth: true,
		permissions: [ 'suppliers' ]
	},
	component: () => import('@/modules/suppliers-index/SuppliersModule.vue'),
	children: [
		{
			name: 'suppliers-index.suppliers.index',
			path: '',
			meta: {
				permissions: [],
			},
			component: () => import('@/modules/suppliers-index/pages/SupplierIndex.vue')
		},
		{
			name: 'suppliers-index.suppliers.create',
			path: 'create',
			meta: {
				permissions: [],
			},
			props: { isNew: true, isEdit: true },
			component: () => import('@/modules/suppliers-index/pages/SupplierPage.vue')
		},
		{
			name: 'suppliers-index.suppliers.show',
			path: ':supplierId(\\d+)',
			meta: {
				permissions: [],
			},
			props: { isNew: false, isEdit: false },
			component: () => import('@/modules/suppliers-index/pages/SupplierPage.vue')
		},
		{
			name: 'suppliers-index.suppliers.edit',
			path: ':supplierId(\\d+)/edit',
			meta: {
				permissions: [],
			},
			props: { isNew: false, isEdit: true },
			component: () => import('@/modules/suppliers-index/pages/SupplierPage.vue')
		}
	]
}
export default suppliersRoutes
