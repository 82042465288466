import innovativeFreshRoutes from '@/modules/innovative-fresh/routes.innovative-fresh.js'
import { createRouter, createWebHistory } from 'vue-router'
import { i18n } from '@/helpers/i18n.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { usePermissionsStore } from '@/stores/usePermissionsStore.js'
import adminRoutes from '@/modules/admin/routes.admin.js'
import auditsRoutes from '@/modules/audits/routes.audits.js'
import authRoutes from '@/modules/auth/routes.auth.js'
import baseRoutes from '@/router/routes.base.js'
import devConsole from '@/helpers/logging/devConsole.js'
import elearningRoutes from '@/modules/elearning/routes.elearning.js'
import labRoutes from '@/modules/lab/routes.lab.js'
import manualsRoutes from '@/modules/manuals/routes.manuals.js'
import registrationRoutes from '@/modules/registrations/routes.registrations.js'
import sensoryRoutes from '@/modules/sensory/routes.sensory.js'
import settingsRoutes from '@/modules/settings/routes.settings.js'
import showError from '@/helpers/alertMessages/showError.js'
import suppliersRoutes from '@/modules/suppliers/routes.suppliers.js'
import userRoutes from '@/modules/user/routes.user.js'
import suppliersIndexRoutes from '@/modules/suppliers-index/routes.supplier-index.js'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		...baseRoutes,
		...authRoutes,
		suppliersRoutes,
		settingsRoutes,
		labRoutes,
		manualsRoutes,
		registrationRoutes,
		...elearningRoutes,
		sensoryRoutes,
		adminRoutes,
		auditsRoutes,
		userRoutes,
		suppliersIndexRoutes,
		innovativeFreshRoutes,
	]
})

router.beforeEach(async (to, from, next) => {
	devConsole('debug', `check route ${to.path}`)
	if (to.matched.some(record => record.meta.requiresAuth)) {
		devConsole('debug', 'route requiresAuth')
		// If there is no token, redirect to /login
		if (!useAuthorizationStore().accessToken()) {
			// Logout the user and remove all session info
			useAuthorizationStore().logout()
			return next({ path: '/login' })
		} else {
			// A token was found. Check if it has expired
			const now = new Date().getTime() / 1000 // get seconds instead of ms
			const refreshAt = useAuthorizationStore().expires() // when the token expires
			const refreshFrom = refreshAt - 3600 // 1 hour from when it expires

			// Session is over. Remove all traces
			if (now > refreshAt) {
				// eslint-disable-next-line no-console
				console.info('session is over')
				showError(i18n.global.t('auth.sessionExpired'))
				useAuthorizationStore().logout()
				return next({ path: '/login' })
			}

			// Session is almost over, try to refresh
			if (now < refreshAt && now > refreshFrom) {
				// eslint-disable-next-line no-console
				console.info('session is about to expire. trying to refresh')
				await useAuthorizationStore().refresh()
			}
		}
	}

	if (to.matched.some(record => record.meta.requiresLogout)) {
		devConsole('debug', 'route requiresLogout')
		if (useAuthorizationStore().accessToken()) {
			return next({ path: '/' })
		}
	}

	if (to.matched.some(record => record.meta.requiresToken)) {
		devConsole('debug', 'route requiresToken')
		if (useAuthorizationStore().accessToken()) {
			return next({ path: '/' })
		}
	}

	if (to.matched.some(record => record.meta.permissions)) {
		devConsole('debug', 'route has permissions')
		const permissions = usePermissionsStore()
		await permissions.fetch()
		if (!permissions.has(...to.meta.permissions)) {
			devConsole('debug', 'missing permissions', to.meta.permissions)
			return next({ name: '403' })
		}
	}

	if (to.matched.some(record => record.meta.redirectIfSupplier)) {
		devConsole('debug', 'route redirectIfSupplier')
		const user = useAuthorizationStore().user()
		if (user.user_type === 'supplier') {
			// Supplier trying to connect to dashboard and not supplier/dashboard
			if (to.name === 'Dashboard') {
				return next({ name: 'suppliers.dashboard' })
			} else {
				return next({
					name: 'suppliers.locations.show',
					params: { locationId: user.relation_id }
				})
			}
		}
	}

	if (!to.matched.length) {
		devConsole('debug', 'route notFound')
		return next('/404')
	}

	return next()
})

export default router
